import type { AxiosError } from 'axios';
import axios from 'axios';
import { logger } from '~/consola/index';


const homepageApi = axios.create({
    baseURL: import.meta.env.VITE_APP_GATEWAY_URL as string,
    headers: {
        "x-api-key": import.meta.env.VITE_APP_HOMEPAGE_API_KEY as string,
    },
    timeout: 5000
});

homepageApi.interceptors.request.use(function (config) {
    logger.info(`REQUEST CONFIG: ${JSON.stringify(config)}`);
    return config;
}, function (error) {
    return Promise.reject(error);
});


homepageApi.interceptors.response.use(function (response) {
    logger.debug(`RESPONSE STATUS: ${response.status}`);
    return response;
}, function (error: AxiosError) {
    logger.error(`RESPONSE: ${JSON.stringify(error)}`);
    return Promise.reject(error);
});

export {
    homepageApi
};